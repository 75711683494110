;(function ($) {

	'use strict';

	function initMap() {

		var styles = [
			{
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#f5f5f5"
					}
				]
			},
			{
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#616161"
					}
				]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#f5f5f5"
					}
				]
			},
			{
				"featureType": "administrative.country",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"color": "#afafaf"
					},
					{
						"visibility": "on"
					},
					{
						"weight": 3
					}
				]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#bdbdbd"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#eeeeee"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#e5e5e5"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#dadada"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#616161"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#e5e5e5"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#eeeeee"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#c9c9c9"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			}
		];
		
		var map = document.getElementById('map');
    var center = {
			lat: parseFloat($(map).attr('data-center-lat')),
			lng: parseFloat($(map).attr('data-center-lng'))
		}

		const mqMobile = window.matchMedia( "(max-width: 575px)" );
		const mqTablet = window.matchMedia( "(min-width: 576px) and (max-width: 1199px)" );
		var zoom = parseInt($(map).attr('data-zoom'));
		
		if (mqTablet.matches) {
			zoom = parseInt($(map).attr('data-tablet-zoom'));
		}

		if (mqMobile.matches) {
			zoom = parseInt($(map).attr('data-mobile-zoom'));
		}

		// console.log(center);
		// console.log(markerPosition);

		// Styles a map in night mode.
		var map = new google.maps.Map(map, {
			center: center,
			zoom: zoom,
			styles: styles,
			disableDefaultUI: true,
		});

		var markerImage = 'ico-pin.svg';
		var markerImageHover = 'ico-pin-hover.svg';
		var markerWidth = 64;
		var markerHeight = 83;

		if ($('html.ie').length) {
			markerImage = 'ico-pin.png';
			markerImageHover = 'ico-pin-hover.png';
			markerWidth = 64;
			markerHeight = 83;
		}

		var image = {
			url: '/images/icons/' + markerImage,
			size: new google.maps.Size(markerWidth, markerHeight),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(markerWidth / 2, markerHeight)
		};

		var $markers = $('#mapMarkers').find('.mapMarkers__item');
		// var objMarkers = [];
		// var objInfoWindows = [];
		for (var i = 0; i < $markers.length; i++) {

			var markerPosition = {
				lat: parseFloat($($markers[i]).attr('data-lat')),
				lng: parseFloat($($markers[i]).attr('data-lng'))
			}

			var marker = new google.maps.Marker({
				position: markerPosition,
				map: map,
				icon: image,
			});

			var content = $($markers[i]).html();
			var infowindow = new google.maps.InfoWindow();

			google.maps.event.addListener(marker, 'click', (function(marker, content, infowindow) { 
				return function() {
					infowindow.setContent(content);
					infowindow.open(map, marker);
				};
			})(marker, content, infowindow));

			google.maps.event.addListener(marker, 'click', function() {
				this.setIcon('/images/icons/' + markerImageHover);
				});

			google.maps.event.addListener(marker, 'mouseover', function() {
				this.setIcon('/images/icons/' + markerImageHover);
			});
			google.maps.event.addListener(marker, 'mouseout', function() {
				this.setIcon('/images/icons/' + markerImage);
			});

		}
	}

	if ($('#map').length) {
		initMap();
	}

})(jQuery);


// menu scripts
;(function ($) {

  "use strict";

  $('.nav__toggle').on('click', function(e){
    e.preventDefault();
    $(this).toggleClass('is-active');
    $('body').toggleClass('menu-active');
  });

  $('.nav__item').on('click', function(e) {
    $('.nav__toggle').removeClass('is-active');
    $('body').removeClass('menu-active');
  });

})(jQuery);

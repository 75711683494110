;(function ($) {

	'use strict';

	var $form = $('#bookingForm');
	if ($form.length) {

		var fp = null;
		var $flatpickrCalendarStart = $("#flatpickrCalendarStart");
		var $flatpickrCalendarEnd = $("#flatpickrCalendarEnd");

		function getPrice() {

			var form = $('#bookingForm');
			var ajaxUrl = form.data('price-endpoint-url');
			var timeout = null;

			window.clearTimeout(timeout);
			var formData = form.serialize();
			var fullUrl = ajaxUrl + '?' + formData;
			// console.log(fullUrl);

			timeout = window.setTimeout(function() {
					$.get(fullUrl).done(function(data) {
							// console.log(data);

							if (data.isAvailable) {
                                const formatter = new Intl.NumberFormat('cs-CZ', {
                                    style: 'currency',
                                    currency: 'CZK'
                                });
								$('#total-price').html(formatter.format(data.totalPrice));
								$('#payment-price').html(formatter.format(data.paymentPrice));
							}
							else {
								// alert(`Bohužel v tomto termínu není možné provést rezervaci.\nJako poslední den vaší rezervace je možné využít: ${data.latestDepartureDate}`);

								$('#latestDepartureDate').text(data.latestDepartureDate);

								// Show modal
								$('#modalBookingError').modal('show');

								let startDate = $flatpickrCalendarStart.val();
								let endDate = new Date(data.latestDepartureDate);
								// console.log(startDate);
								// console.log(endDate);

								fp.setDate([startDate, endDate], false);
							}

					}).fail(function(data) {
							console.error(data);
					});
				}, 300);

		}

		// two modals on close first hack
		$('#modalBookingError').on('hidden.bs.modal', function (event) {
			$('body').addClass('modal-open');
		})

		$('.checkPriceOnChange').on('change', function(e) {
			getPrice();
		});

		$('.bookingOpenBtn').on('click', function(e) {

			// user click on booking btn  - show floating button
			$('body').addClass('showFloatingButton');

			var roomId = $(this).attr('data-room-id');
			var roomCapacity = $(this).attr('data-capacity');
			var roomCapacityMin = parseInt($(this).attr('data-min-people-count'));

			var $calendar = $(this).closest('.room').find('.flatpickrCalendarInline');

			setFormSelect(roomId);
			setFormQuantity(roomCapacity);
			setFormAdultsToMin(roomCapacityMin);
			setDisabledDates($calendar);

			// set dates
			setDates($calendar);

			//get price by default settings
			getPrice();

			$(document).trigger('bookingFormOpen');

		});

		function setDates($calendar) {
			var start = Date.parse($calendar.attr('data-start'));
			var end = Date.parse($calendar.attr('data-end'));

			// console.log(start);
			// console.log(end);

			if (start && end) {
				fp.setDate([start, end], true);
			}
		}

		function setDisabledDates($calendar) {

			var disabledDates = $calendar.data('disabled-dates');			// parse string to array automaticaly
			// console.log(disabledDates);

			if ($flatpickrCalendarStart.hasClass('flatpickr-input')) {
				$flatpickrCalendarStart.flatpickr().destroy();
			}
			if ($flatpickrCalendarEnd.hasClass('flatpickr-input')) {
				$flatpickrCalendarEnd.flatpickr().destroy();
			}

			$flatpickrCalendarStart.flatpickr({
				locale: 'cs',
				mode: "range",
				minDate: new Date().fp_incr(1),
				dateFormat: "d.m.Y",
				disable: disabledDates,
				onChange: function(selectedDates, datestr, instance) {

					var start = new Date(selectedDates[0]);
					var end = new Date(selectedDates[1]);

					// if same date
					if (start.getTime() == end.getTime()) {
						fp.setDate([start, start.fp_incr(1)], true);
						// $flatpickrCalendarStart.val(($flatpickrCalendarStart).val().split(' ')[0]); //hack
					}

				},
				"plugins": [new rangePlugin({ input: "#flatpickrCalendarEnd"})],
			});

			fp = $($flatpickrCalendarStart)[0]._flatpickr; // ulozim si instanci, abych mohl upravovat pickery dynamicky

		}

		function setFormSelect(id) {
			var $selectRoom = $('#bookingFormRoom');
			$selectRoom.find('option[value="' + id + '"]').prop('selected', true);
		}

		function setFormQuantity(capacity) {

			var $quantityAdults = $('.quantity__value--adults');
			var $quantityChildren = $('.quantity__value--children');
			$quantityAdults.attr('data-max', capacity);
			$quantityChildren.attr('data-max', capacity - 1);
		}

		function setFormAdultsToMin(count) {

			var $quantityAdults = $('.quantity__value--adults');
			// var max = parseInt($quantityAdults.attr('data-max'));

			// if (count >= max) {
			// 	$quantityAdults.closest('.quantity').addClass('quantity--max');
			// }

			$quantityAdults.attr('data-minInRoom', parseInt(count));

			$('#formFooterInfo').find('span').text(count);

			$quantityAdults.attr('data-value', parseInt(count));
			$quantityAdults.attr('value', parseInt(count));
			$quantityAdults.val(parseInt(count)).change();

		}

		var $flatpickrCalendarInline = $('.flatpickrCalendarInline');
		for(var i = 0; i < $flatpickrCalendarInline.length; i++) {

			var $calendar = $($flatpickrCalendarInline[i])
			var disabledDates = $calendar.data('disabled-dates');			// parse string to array automaticaly

			$calendar.flatpickr({
				inline: true,
				locale: 'cs',
				mode: "range",
				minDate: new Date().fp_incr(1),
				dateFormat: "d.m.Y",
				disable: disabledDates,
				onChange: function(selectedDates, datestr, instance) {
					if (selectedDates.length == 2) {
						var start = selectedDates[0];
						var end = selectedDates[1];

						// pokud stejný den
						if (start.getTime() == end.getTime()) {
							instance.setDate([start, start.fp_incr(1)], true);
						}
						else {
							$(instance.element).attr('data-start', start);
							$(instance.element).attr('data-end', end);
							// console.log(start);
							// console.log(end);
						}
					}
				}
			});

		}

		// console.log($(".flatpickrCalendar"));

		// // console.log($('.flatpickr-day'));

		// var $days = $('.flatpickr-day');
		// for(var i = 0; i < $days.length; i++) {
		// 	var $d = $($days[i]);
		// 	if ($d.date)
		// }

		// // https://stackoverflow.com/questions/40324781/flatpickr-ondaycreate-add-class
		// flatpickr("#dayCreate", {
		// 	onDayCreate: function(dObj, dStr, fp, dayElem) {
		// 		var date = dayElem.dateObj,
		// 			// Note the + 1 in the month.
		// 			key = date.getFullYear() + get2DigitFmt(date.getMonth() + 1) + get2DigitFmt(date.getDate()),
		// 			value = classDict[dates[key]];
		// 		if (value) {
		// 			dayElem.className += ' ' + value;
		// 		}
		// 	}
		// });


		// Tags
		/*var inputChildrenAge = $('#children_age');
		new Tagify(inputChildrenAge[0], {
			duplicates: true,
		});*/

		// omezeni zobrazeni veku deti
		// $('.quantity__value--children').on('change', function(e) {
		// 	var count = $(this).val();
		// 	// console.log(count);

		// 	var $tags = $('tag');
		// 	// console.log($tags);

		// 	for (var i = 0; i < $tags.length; i++) {
		// 		if (i < count) {
		// 			$($tags[i]).removeClass('d-none');
		// 		}
		// 		else {
		// 			$($tags[i]).addClass('d-none');
		// 		}
		// 	}

		// });

	}


})(jQuery);

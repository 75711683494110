
// import libs
// import './libs/cookieBar';

var $header = $('#header');
var headerHeight = $header.outerHeight() + 50;

// import components
import './components/ie-detection.js';
import './components/menu.js';
import './components/map.js';
import './components/booking.js';
// import './components/modal.js';

// init
// $('.lazy').Lazy({
//     // your configuration goes here
//     scrollDirection: 'vertical',
//     effect: 'fadeIn',
//     effectTime: 150,
//     visibleOnly: true,
//     threshold: 500,
//     bind: 'event',
//     onError: function(element) {
//         console.log('error loading ' + element.data('src'));
//     }
// });

// ----------------------------------------
//  scroll to
var $scrollToItems = $('.scrollTo');
$.each($scrollToItems, function() {
    $(this).on('click', function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        if (typeof href === 'undefined') {
            href = $(this).attr('data-href');
        }
        var offset = $(this).attr('data-offset');

        // if booking strip scroll to
        if ($(this).hasClass('scrolling-strip')) {
            const mq = window.matchMedia( "(max-width: 576px)" );
            if (mq.matches) {
                offset = 0;
            }
        }

        var $target = $(href);
        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top - headerHeight + ((typeof offset !== 'undefined') ? parseInt(offset) : 0)
            }, {
                duration: 300,
                easing: 'swing'
            });
        }
    });
});
// ----------------------------------------

// scripts here
// ----------------------------------------

// Bootstrap dropdown
// $('.dropdown-toggle').dropdown();

// collapse
// $('.collapse__toggle, .collapse__toggle > label').on('click', function(e) {
//     $(this).closest('.collapse').toggleClass('show');
// });


// teaser carousel
$('#teaserCarouselImages').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  pauseOnFocus: false,
  dots: false,
  arrows: false,
  asNavFor: '#teaserCarouselNav',
  speed: 500,
  fade: true,
  cssEase: 'linear',
});

$('#teaserCarouselNav').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  // autoplay: true,
  // autoplaySpeed: 3000,
  dots: false,
  arrows: false,
  asNavFor: '#teaserCarouselImages',
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
      }
    },
  ]
});

$('#aboutCarouselImages').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  pauseOnFocus: false,
  dots: false,
  arrows: false,
  speed: 500,
  fade: true,
  cssEase: 'linear',
});

$('.gallery__carousel').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  dots: true,
  arrows: false,
  speed: 500,
  fade: true,
  cssEase: 'linear',
});

// Magnific popup
// https://codepen.io/dimsemenov/pen/GAIkt
$('.gallery').each(function() {

  const breakpoint = window.matchMedia( "(max-width: 991px)" );
  var magnificClass = '.magnific';

  if (breakpoint.matches) {
    magnificClass = '.magnific-mobile';
  }

  $(this).magnificPopup({
    delegate: magnificClass,
    gallery: {
      enabled: true
    },
    type: 'image',
    removalDelay: 500, //delay removal by X to allow out-animation
    callbacks: {
      beforeOpen: function() {
        // just a hack that adds mfp-anim class to markup
          this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
          this.st.mainClass = this.st.el.attr('data-effect');
      }
    },
    closeOnContentClick: true,
    midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
  });

  
});

// modal Age confirm
if (!Cookies.get('age-confirmation')) {
  $('#modalAge').modal('show');
}

$('#ageYes').on('click', function(e) {
  $('#modalAge').modal('hide');
  Cookies.set('age-confirmation', 1, { expires: 999 });
})

$('#ageNoContent').hide();
$('#ageNo').on('click', function(e) {
  $('#modalAge').addClass('no-events');
  $('#ageDefaultContent').hide();
  setTimeout(function(e) {
    $('#ageNoContent').show();
  }, 500);
})

$(window).ready(function() {
  if ($('.page--products').length) {
    $('.parallax').paroller();
    // $(".paroller").paroller({ factor: '-1', type: 'foreground', direction: 'horizontal' });
  }
});